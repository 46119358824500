#menu-top-container {
  .menu-top {
    margin: 0;
    padding: 0;
    list-style: none;
    & > li {
      padding: 0 pixelsToRem(15);
      display: inline-block;
      & > a {
        color: #4B4B4B;
        font-weight: 500;
        font-size: pixelsToRem(19);
        text-decoration: none;
        padding: 4px 8px;
        &:hover, &:focus {
          background-color: $main-primary-color;
          color: #fff;
        }
      }
    }
  }
}