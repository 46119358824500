footer {
	background-color: #fff;
	#main-col-footer-1 {
		width: 100%;
		height: 100%;
	}
	#main-col-footer-2 {
		width: 100%;
		height: 100%;
		padding: pixelsToRem(40);
		background-color: $main-primary-color;
		float: right;
		color: #fff;
		@include addFontSizeLineHeightPxToRem(14, 28);
		@media(max-width: 1300px) {
			width: 100%;
		}
		h2 {
			@include addFontSizeLineHeightPxToRem(45, 59);
			color: #fff;
			text-transform: uppercase;
			font-weight: 900;
		}
		h3 {
			margin-top: 80px;
			font-size: pixelsToRem(24);
			font-weight: 700;
		}
	}
	
	#main-footer-inside-last {
		.col-ftl {
			background-color: #35363A;
			text-align: center;
			padding-top: 4px;
			padding-bottom: 8px;
			@media(max-width: 767px) {
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}
	.contact-list {
		.item {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: 1.5rem;
			&:last-child {
				margin-bottom: 0;
			}
			.content-one {
				margin-right: 10px;
				margin-top: pixelsToRem(10);
			}
		}
		a {
			color: $main-alternate-color;
			&:hover, &:focus {
				color: darkred;
			}
		}
		.info-show-hide {
			display: inline-block;
		}
		.info-secret {
			display: none;
		}
	}
}