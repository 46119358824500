$main-base-font-size-int: 24;
$main-base-line-height-int: 48;
$main-base-font-size: $main-base-font-size-int + px;
$main-base-line-height: $main-base-line-height-int / $main-base-font-size-int;
$main-base-rwd-font-size: 16px;

$main-primary-color: #FF8800;
$main-secondary-color: #42BFDD;
$main-alternate-color: #084B83;
$main-body-text-color: #231f20;
$main-primary-font-family: 'Roboto', sans-serif;
$main-primary-font-size: 24px;
$main-primary-line-height: 48px;
$main-container-max-width: 1300px;
$main-col-padding-x: 32px;
$main-color-one: #231f20;