body {
	overflow-x: hidden;
	font-size: 1rem;
	line-height: $main-base-line-height;
	font-family: $main-primary-font-family;
	color: $main-body-text-color;
	font-weight: 400;
	background-color: #fff;
	//@media(max-width: 767px) {
	//	font-size: 18px;
	//	line-height: normal;
	//}
}