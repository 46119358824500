.main-section {
	padding-top: pixelsToRem(120);
	padding-bottom: pixelsToRem(120);
	
	.main-section-body {
		padding-left: 0;
		padding-right: 0;
		@media(max-width: 767px) {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
	
	.main-section-body-2 {
		padding-left: 0;
		padding-right: 0;
	}
	
	.col-content {
		padding: 40px 32px;
		@media(max-width: 767px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	h2 {
		@include addFontSizeLineHeightPxToRem(45, 59);
		font-weight: 900;
		text-transform: uppercase;
		color: $main-primary-color;
		margin-top: pixelsToRem(40);
		margin-bottom: pixelsToRem(20);
		@media(max-width: 767px) {
			font-size: pixelsToRem(28);
			line-height: normal;
		}
		&.h2-alt {
			text-transform: none;
			@include addFontSizeLineHeightPxToRem(71, 80, $rwdSizePercent: 40);
		}
	}
	
	h3 {
		@include addFontSizeLineHeightPxToRem(35, 48);
		font-weight: 900;
		text-transform: uppercase;
		color: $main-color-one;
		@media(max-width: 767px) {
			font-size: pixelsToRem(26);
			line-height: normal;
		}
	}

	&.section-1 {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 41.66666667%;
			height: 429px;
			background-color: #DDDEE0;
			@media(max-width: 767px) {
				display: none;
			}
		}
		.section-img {
			position: relative;
			z-index: 1;
		}
	}

	&.section-2 {
		background-color: #F0F6F6;
		background-image: url("../precompile/bg-content-1.png");
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: contain;
		
		h2 {
			text-transform: none;
			@include addFontSizeLineHeightPxToRem(43, 57);
			@media(max-width: 767px) {
				font-size: pixelsToRem(28);
				line-height: normal;
			}
		}
		
		.main-section-body {
			padding-left: 50px;
			padding-right: 50px;
			padding-top: 60px;
			padding-bottom: 60px;
			background-color: #fff;
			@media(max-width: 767px) {
				padding-left: 10px !important;
				padding-right: 10px !important;
			}
		}
		
		&.section-2-1 {
			background-image: url("../precompile/bg-content-2.png");
			background-position: center center;
			background-size: cover;
		}
	}
	
	&.section-3 {
		background-color: $main-primary-color;
		padding-top: 15px;
		padding-bottom: 15px;
		.main-title {
			@include addFontSizeLineHeightPxToRem(26, 34);
			font-weight: 900;
			text-transform: uppercase;
			color: #fff;
		}
	}
	
	&.section-4 {
		background-color: #F0F6F6;
	}
	
	&.section-5 {
		background-image: url("../precompile/bg-content-3.png");
		background-repeat: no-repeat;
		background-size: cover;
	}
}