.main-carousel {
	position: relative;
	
	.crl-item {
		position: relative;
		text-decoration: none;
		display: block;
		&::after {
			display: none;
			content: "";
			position: absolute;
			background-color: rgba(255, 136, 0, .56);
			bottom: 0;
			left: 0;
			width: 100%;
			height: calc(100% + 80px);
			background-image: url("../precompile/lupa.svg");
			background-repeat: no-repeat;
			background-position: center;
		}
		&:hover, &:focus {
			text-decoration: none;
			&::after {
				display: block;
			}
		}
		
		.crl-image {

		}
		
		.crl-title {

		}
		
	}
	
	.slick-slide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 80px;
		padding-bottom: 10px;
		padding-right: 62px;
		padding-left: 0;
	}
	
	.crl-arrows {
		position: absolute;
		z-index: 2;
		top: 38%;
		display: block;
		
		&#crl-prev {
			left: -10px;
			right: auto;
			@media(max-width: 767px) {
				left: 10px;
			}
		}
		
		&#crl-next {
			right: -10px;
			left: auto;
			@media(max-width: 767px) {
				right: 10px;
			}
		}
		
	}
	
}

.carousel-info {
	display: none;
}

.main-carousel-area {
	font-size: pixelsToRem(18);
	.carousel-info {
		& > div:last-child {
			@media(min-width: 768px) {
				padding-left: 0;
			}
		}
		.d-flex > div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				display: block;
				margin-top: 0 !important;
			}
		}
	;
	}
}