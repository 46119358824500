form {
	label.error {
		background-color: rgba(255, 255, 255, .5);
		color: red;
		font-size: pixelsToRem(12);
		line-height: normal;
		font-weight: 700;
		padding: 4px 8px;
		display: block;
		margin-top: 4px;
		font-style: italic;
		
		&#data_reg-error {
			margin-top: 30px !important;
		}
	}
}