.btn-primary {
	color: #fff;
}

.btn-lp {
	@include addFontSizeLineHeightPxToRem(18, 24);
	font-weight: 900;
	padding: 15px;
	&:hover, &:focus {
		color: #fff;
	}
}

.btn-lp-2 {
	text-transform: uppercase;
	font-weight: 900;
	@include addFontSizeLineHeightPxToRem(28, 37);
	padding: 15px;
	width: 30%;
	@media(max-width: 1300px) {
		width: 100%;
		margin-top: 10px;
	}
	&:hover, &:focus {
		color: #fff;
	}
}

.btn-lp-3 {
	color: $main-secondary-color;
	padding: 12px 20px;
	background-color: #fff;
	border: 1px solid $main-primary-color;
	text-transform: uppercase;
	font-weight: 900;
	font-size: pixelsToRem(18);
	line-height: normal;
	border-radius: 24px;
	margin-right: 10px;
	margin-left: 10px;
	width: 190px;
	@media(max-width: 767px) {
		margin-top: 4px;
		margin-bottom: 4px;
	}
}

.btn-lp-4 {
	font-weight: 900;
	text-transform: uppercase;
	padding: 40px;
	color: #fff;
	@include addFontSizeLineHeightPxToRem(28, 37);
	text-align: center;
	width: 100%;
	@media(max-width: 767px) {
		font-size: pixelsToRem(32);
		line-height: normal;
		margin-top: pixelsToRem(-150) !important;
	}
	&:hover, &:focus {
		color: #fff;
	}
}

.main-link-underline {
	text-decoration: underline;
	&:hover, &:focus {
		text-decoration: none;
	}
}

.btn {
    color: #fff;
    &:hover, &:focus {
        color: #fff;
    }
	&.btn-lp-5 {
		border: 2px solid $main-primary-color;
		color: $main-color-one;
		text-transform: uppercase;
		color: $main-primary-color;
		&:hover, &:focus {
			background-color: $main-primary-color;
			color: #fff;
		}
	}
}

.main-link-one {
	text-decoration: none;
	color: $main-primary-color;
	font-weight: bold;
	border-bottom: 2px solid $main-primary-color;
	&:hover, &:focus {
		border-bottom: none;
	}
}
