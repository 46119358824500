.main-boxes {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;

	.main-box {
		background-color: #fff;
		text-align: center;
		padding: 45px 10px 34px 10px;
		box-shadow: 0px 3px 6px #00000029;
		width: 244px;
		height: 276px;
		margin-left: 32px;
		margin-right: 32px;

		.main-box-title {
			color: $main-primary-color;
			@include addFontSizeLineHeightPxToRem(45, 59);
			font-weight: 900;
			margin-top: 10px;
            min-height: pixelsToRem(64);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
		}

		.main-box-subtitle {
			color: $main-color-one;
			font-size: pixelsToRem(14);
			line-height: normal;
			font-weight: 400;
		}

		hr {
			border: 1px solid $main-color-one;
			opacity: 1;
			margin: 5px auto 15px auto;
			width: 40%;
		}

		.div-img {
			height: 65px;
			display: table-cell;
			vertical-align: bottom;
			width: 100vh;
			text-align: center;
			@media(max-width: 1300px) {
				height: auto;
			}
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
		}
		@media(max-width: 1300px) {
			margin-top: 4px;
			margin-bottom: 4px;
			height: auto;
		}
	}

	&.main-boxes-1 {
		.main-box {
			.main-box-title {
				@include addFontSizeLineHeightPxToRem(24, 32);
				text-transform: uppercase;
			}
			.div-img {
				vertical-align: top;
				height: 72px;
				img {
					margin-top: -80px;
					@media(max-width: 1300px) {
						margin-top: 0;
					}
				}
				&#div-img-spec {
					img {
						margin-top: -132px;
						@media(max-width: 1300px) {
							margin-top: 0;
						}
					}
				}
				@media(max-width: 1300px) {
					height: auto;
				}
			}
			hr {
				margin-top: 20px;
			}
		}
	}

	&.main-boxes-2 {
		.main-box {
			.main-box-title {
				@include addFontSizeLineHeightPxToRem(20, 26);
				text-transform: uppercase;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				flex-wrap: wrap;
				min-height: 52px;
				margin-top: 0;
			}
			.div-img {
				vertical-align: top;
				height: 72px;
				img {
					margin-top: -80px;
					@media(max-width: 1300px) {
						margin-top: 0;
					}
				}
				@media(max-width: 1300px) {
					height: auto;
				}
			}
			hr {
				margin-top: 15px;
			}
		}
	}
}

.main-boxes-title {
	background-color: #fff;
	padding: 25px 35px;
	@include addFontSizeLineHeightPxToRem(45, 59);
	font-weight: 900;
	color: $main-primary-color;
	text-align: center;
	@media(max-width: 767px) {
		font-size: pixelsToRem(32);
		line-height: normal;
	}

	span {
		color: $main-color-one;
	}
}
