form.main-form {
	margin-top: 20px;
	::placeholder {
		font-size: 14px;
		color: $main-alternate-color;
	}
	input {
		&[type="text"], &[type="tel"], &[type="email"], &[type="checkbox"] {
			padding: 0.375rem 0.75rem;
			border: none;
			font-size: pixelsToRem(14);
			line-height: normal;
			color: $main-alternate-color;
			&:focus {
				outline: 3px solid $main-secondary-color;
			}
		}
	}
	textarea {
		padding: 15px;
		border: none;
		font-size: pixelsToRem(14);
		line-height: normal;
		color: $main-alternate-color;
		&:focus {
				outline: 3px solid $main-secondary-color;
			}
	}
	.custom-checkbox {
		color: #fff;
		text-transform: none;
		@include addFontSizeLineHeightPxToRem(12, 16);
		font-weight: 400;
		border: none;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		.custom-control-input {

		}
		label {
			padding-left: pixelsToRem(10);
			padding-top: 0;
		}
	}
}

form {
	.form-group {
		margin-bottom: 1rem;
	}
}