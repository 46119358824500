.list-one {
	list-style-type: none;
	padding-left: 0;
	margin-left: 0;
	li {
		background-image: url("../precompile/li-1.png");
		background-repeat: no-repeat;
		background-position-y: 17px;
		color: $main-primary-color;
		@media(max-width: 767px) {
			background-position-y: 6px;
		}
		& > div {
			margin-left: 37px;
			color: $main-color-one;
		}
		strong {
			color: $main-primary-color;
		}
	}
}

.list-two {
	list-style-type: lower-alpha;
}

.list-three {
	list-style-type: square;
}

.list-four {
	list-style-type: disc;
}

.list-roman {
	list-style-type: upper-roman;
}

@media(max-width: 767px) {
	ol, ul {
		padding-left: 20px;
	}
}