header {
	padding-top: 71px;

	#main-col-logo {
		@media(max-width: 767px) {
			padding-top: 12px;
		}
		@media(max-width: 575px) {
			padding-top: 0;
		}
	}

	.main-header-body {
		padding-left: 0;
		padding-right: 0;
	}

	#main-top-1 {
		color: #878787;
		@include addFontSizeLineHeightPxToRem(19, 25);
		padding-top: 10px;
		padding-bottom: 14px;
		background-color: #fff;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 9999;
		strong {
			color: $main-alternate-color;
		}
        @media(max-width: 1400px) {
            font-size: pixelsToRem(14);
            .btn {
                display: none;
            }
        }
	}

	#main-top-2 {
		background-image: url("../precompile/bg-top-2.jpg");
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: top center;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		@media(max-width: 1200px) {
			padding-top: 0;
			background-size: cover;
		}

		#main-top-content-1 {
			max-width: 670px;
			float: right;
			padding: 30px 60px;
			background-color: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(22px);
			--webkit-backdrop-filter: blur(22px);
			@include addFontSizeLineHeightPxToRem(24, 45);
			height: 100%;

			@-moz-document url-prefix() {
				background-color: rgba(240, 246, 246, 0.94);
			}
            @media(max-width: 1400px) {
                font-size: pixelsToRem(22);
                //max-width: 100%;
            }
			@media(max-width: 767px) {
				line-height: normal;
			}
            @media(max-width: 550px) {
                padding-left: 10px;
                padding-right: 10px;
            }
            h1 {
				font-size: pixelsToRem(34);
				line-height: normal;
				color: $main-primary-color;
				font-weight: 900;
				margin-bottom: 40px;
                @media(max-width: 1400px) {
                    font-size: pixelsToRem(24);
                    margin-bottom: pixelsToRem(20);
                }
                @media(max-width: 767px) {
                    font-size: pixelsToRem(30);
                }
			}
		}

        #main-top-content-2 {
            max-width: 720px;
            float: right;
            padding: 30px 60px;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(22px);
            --webkit-backdrop-filter: blur(22px);
            margin-bottom: pixelsToRem(250);

            @media(max-width: 1400px) {
                //max-width: 100%;
                margin-top: 15px;
                margin-bottom: 15px;
				margin-right: 30px;
            }

            @media(max-width: 1400px) {
                //max-width: 100%;
                margin-bottom: 15px;
            }

            @media(max-width: 850px) {
				margin-top: 30px;
				margin-bottom: 120px;
				max-width: 70%;
				margin-left: 30px;
				float: left;
				padding-left: 10px;
            }

            @media(max-width: 550px) {
                //width: 100%;
                //max-width: 100%;
                margin-bottom: 120px;
                border-bottom: 5px solid #fff;
				margin-top: 30px;
				padding-right: 10px;
            }

            @-moz-document url-prefix() {
                background-color: rgba(240, 246, 246, 0.94);
            }
            @media(max-width: 767px) {

            }

            .mtc-title {
                @include addFontSizeLineHeightPxToRem(45, 59);
                text-transform: uppercase;
                color: $main-primary-color;
                font-weight: 900;
                margin-bottom: pixelsToRem(8);
                @media(max-width: 1400px) {
                    font-size: pixelsToRem(26);
                }
                @media(max-width: 767px) {
                    font-size: pixelsToRem(34);
                }
            }

			.btn {
				background-color: $main-primary-color;
				border-color: $main-primary-color;
				&:hover, &:focus {
					background-color: $main-color-one;
					border-color: $main-color-one;
				}
			}

            .mtc-sub-title {
                @include addFontSizeLineHeightPxToRem(35, 48);
                text-transform: uppercase;
                color: $main-color-one;
                font-weight: 900;
                margin-bottom: pixelsToRem(8);
                @media(max-width: 1400px) {
                    font-size: pixelsToRem(26);
                }
                @media(max-width: 767px) {
                    font-size: pixelsToRem(30);
                }
            }
        }

        #mtc-image-2 {
            position: absolute;
            z-index: 1;
            top: -40px;
            left: -80px;
            @media(max-width: 1400px) {
                //display: none;
            }
			@media(max-width: 850px) {
				top: auto;
				bottom: 100px;
			}
			@media(max-width: 767px) {
				width: 30%;
				left: 60%;
				top: auto;
				bottom: 40px;
			}
        }

		#mtc-image-3 {
			position: absolute;
			z-index: 2;
			bottom: 90px;
			left: -270px;
			@media(min-width: 1400px) {
				width: 240px;
				left: -300px;
				bottom: 60px;
			}
			@media(max-width: 1399px) {
				bottom: -100px;
			}
			@media(max-width: 850px) {
				bottom: 0;
			}
			@media(max-width: 767px) {
				left: 30%;
				width: 25%;
				bottom: 30px;
			}
		}

		#kontakt {
			padding: 20px 360px 20px 30px;
			position: relative;
			height: 100%;
			@media(max-width: 1300px) {
				padding: 10px 10px 10px 10px;
			}
			background-color: $main-primary-color;
			color: #fff;
			.main-title {
				font-weight: 900;
				text-transform: uppercase;
				margin-bottom: pixelsToRem(10);
				color: $main-alternate-color;
				@include addFontSizeLineHeightPxToRem(40, 45);
				.main-subtitle {
					font-size: pixelsToRem(17);
				}
			}
			.main-description {
				@include addFontSizeLineHeightPxToRem(24, 28);
				font-weight: 700;
				margin-top: pixelsToRem(10);
				margin-bottom: pixelsToRem(10);
				.desc-small {
					@include addFontSizeLineHeightPxToRem(14, 28);
					font-weight: 400;
				}
			}
		}

	}
}
