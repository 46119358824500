// Containers START

.container {
	max-width: $main-container-max-width + ($main-col-padding-x * 2);
	@media(max-width: 767px) {
		max-width:  767px;
	}
}

// Containers STOP

// Cols START

.row > div {
	padding-left: $main-col-padding-x;
	padding-right: $main-col-padding-x;
	@media(max-width: 767px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

// Cols STOP

.main-mail {
	color: $main-alternate-color;
	margin-left: 30px;
	white-space: nowrap;
	&:hover, &:focus {
		color: $main-secondary-color;
		text-decoration: none;
	}
	@media(max-width: 767px) {
		display: block;
	}
}

.main-phone {
	color: $main-primary-color;
	margin-left: 30px;
	white-space: nowrap;
	&:hover, &:focus {
		color: $main-secondary-color;
		text-decoration: none;
	}
	@media(max-width: 767px) {
		display: block;
	}
}

.main-hr {
	border: 1px solid $main-secondary-color;
	margin-top: 34px;
	margin-bottom: 34px;
	width: 20%;
	margin-right: auto;
	margin-left: auto;
	@media(max-width: 767px) {
		width: 100%;
	}
}

.main-text-color-primary {
	color: $main-primary-color;
}

.main-text-color-secondary {
	color: $main-secondary-color;
}

.main-text-color-alternate {
	color: $main-alternate-color;
}

.main-bg-color-primary {
	background-color: $main-primary-color;
}

.main-bg-color-secondary {
	background-color: $main-secondary-color;
}

.main-bg-color-alternate {
	background-color: $main-alternate-color;
}

.main-bg-color-one {
	background-color: $main-color-one;
}

.main-text-color-one {
	color: $main-color-one;
}

.main-bg-lightlsilver-1 {
	background-color: #F6F6F8;
}

.main-bg-color-section-alt {
	background-color: #F0F6F6;
}

.section-for-title-1 {
	.blp4-margin {
		margin-top: -122px;
	}
}

.main-bottom-padding-1 {
	padding-bottom: 140px !important;
	@media(max-width: 767px) {
		padding-bottom: 95px !important;
	}
}

//Sticky header START

.main-sticky {
	box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
	padding-bottom: 10px !important;
}

//Sticky header STOP

table {
	font-size: pixelsToRem(18);
	@media(max-width: 767px) {
		font-size: 10px;
	}
}

a {
	color: $main-color-one;
}

strong {
	font-weight: 400;
}

p {
	margin-top: pixelsToRem(20);
}