#cookies-message {
	padding: 10px 0px;
	@include addFontSizeLineHeightPxToRem(14, 22);
	box-shadow: 0 0 15px 5px rgba(194,191,183,0.8);
	text-align: center; 
	position: fixed; 
	bottom: 0px; 
	background: rgba(39, 34, 35, .9);
	color: white;
	width: 100%; 
	z-index: 888;
	
	#main-title-cookie {
		font-weight: 700;
		text-transform: uppercase;
	}
	
	.wh-link {
		color: $main-secondary-color;
		font-weight: 600;
	}

	#accept-cookies-checkbox {
		margin-right: 20px;
	}
}