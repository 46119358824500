@import 'variables-start';
@import 'vars-frameworks';

@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import '../../node_modules/slick-carousel/slick/slick';
$slick-font-path: "../../node_modules/slick-carousel/slick/fonts/";
$slick-loader-path: "../../node_modules/slick-carousel/slick/";
@import '../../node_modules/slick-carousel/slick/slick-theme';

@import "../../node_modules/slicknav/scss/slicknav";

//customs
@import 'fonts';
@import 'variables';
@import 'program';
@import 'variables-end';
@import 'html';
@import 'general';
@import 'header';
@import 'body';
@import 'main_div_body';
@import 'animations';
@import 'buttons_and_links';
@import 'sections';
@import 'lists';
@import 'forms';
@import 'main_boxes';
@import 'jquery_validate';
@import 'main_modals';
@import 'cookie_box';
@import 'footer';
@import 'main-carousel';
@import "menu-top";
@import "slicknav";