.s-animate {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.s-animate.s-animate--scale:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.s-animate-two {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.s-animate-two.s-animate-two--scale:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}