//Bootstrap START

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

//Theme colors
$primary: #42BFDD;
$secondary: $main-primary-color;
//$success: [color];
//$info: [color];
//$warning: [color];
//$danger: [color];
//$light: [color];
//$dark: [color];

//Bootstrap STOP